@import "../../node_modules/antd/lib/style/themes/default.less";
@green-8: #304a00;
@green-9: #223400;
@badge-color: #236182;
@success-color: #52c41a;

.enforce-gap(@gap) {
  margin: calc(-@gap / 2);
  > * {
    margin: calc(@gap / 2);
  }
}

.ant-tooltip-inner {
  text-align: center;
}
