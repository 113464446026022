@import "../../styles/vars.less";

.sign-in-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  .branding-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .sign-in-form-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    .form-base-container {
      flex: 0 1 250px;
      .log-in-button {
        float: right;
      }
    }
  }
}
.forgot-password-link {
  padding: 0;
  .ant-typography {
    color: @primary-color;
  }
}

@media screen and (min-width: 600px) and (orientation: landscape) {
  .sign-in-page {
    flex-direction: row;
    .branding-container {
      justify-content: center;
      .logo-app-name-container {
        padding-bottom: 50px;
      }
    }
    .sign-in-form-container {
      align-items: center;
    }
  }
}

@media screen and (min-width: 1200px) and (orientation: landscape) {
  .sign-in-page {
    margin: 0 15%;
  }
}

@primary-color: #618223;@brand-primary: #FF9900;@success-color: #52c41a;@layout-header-background: inherit;@layout-body-background: #FFFFFF;@typography-title-margin-bottom: 0.2em;@table-padding-horizontal: 8px;@table-padding-vertical: 8px;